<template>
<div class="reward-view">
  <div class="wrapper">
    <div class="banner-about">
      <div class="title">"If the human brain were so simple that we could understand it, we would be so simple that we couldn’t."</div>
      <div class="name">- Emerson M. Pugh</div>
    </div>
    <div class="about">
      <h1>Points Calculation Rules</h1>
      <div class="about-content">
        <p>1. Every time you open an article you haven't read before, you will get 2 points.</p>
        <p>2. The total points of one article is the highest ever points you get from it.</p>
        <p>3. Points for one article in one trial = the number of answered general questions * the number of correct answers for specific questions.</p>
        <p>4. Providing a new article will lead to 5 points rewarded to you.</p>
        <p>5. If your article passes modification we will provide you with 20 extra points.</p>
        <p>6. Each approved question you provide to an article will give you 20 points.</p>
        <p>7. Leaving a comment and getting it approved will give you 2 points.</p>
      </div>
      <div class="about-line"></div>
      <h1>Contact Us</h1>
      <div class="about-content">
        For any inquiries, suggestions or complaints, please email <a href="mailto:gedi.luksys@ed.ac.uk">gedi.luksys@ed.ac.uk</a><br>
        This project is currently under development, so we very much welcome your input.
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "RewardSystem"
}
</script>

<style scoped>
.banner-about{
  display: flex;
  width: 1240px;
  height: 180px;
  /*padding: 0 11%;*/
  font-size: 28px;
  color: #14279B;
  margin: 0 auto;
  line-height: 1.5;
  font-family: "Poppins-SemiBold";
  /*border: 1px solid;*/
  border-radius: 0 0 80px 80px;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  background:url('../assets/images/bg-banner-about.jpg') no-repeat center top;
}
.banner-about .title{
  text-align: center;
  width: 1000px;
  margin: 0 auto;
}
.banner-about .name{
  text-align: right;
  width: 1000px;
  margin: 0 auto;
}
.about{
  display: block;
  width: 1085px;
  margin: 0 auto;
  padding: 30px 0;
  text-align: center;
}
.about h1{
  margin-top: 10px;
  margin-bottom: 30px;
  font-size: 32px;
  font-family: "Poppins-Bold";
  color: #14279B;
}
.about-content{
  font-size: 14px;
  color: #000000;
  line-height: 1.8;
  padding-bottom: 30px;
}
.about-content a{
  color: #4A88F8;
}
.about-content a:hover{
  text-decoration: underline;
}
.about-content .imgs img{
  display: block;
  margin: 0 auto;
}
.about-line{
  display: block;
  height: 1px;
  width: 100%;
  margin-bottom: 35px;
  background-color: #d9d9d9;
}
</style>